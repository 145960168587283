import React, { createContext, useContext } from "react"

const locationContext = createContext({ pathname: "" })
const setLocationContext = createContext(() => {})

const LocationContextProvider = ({ children }) => {
  const [data, setData] = React.useState({ pathname: "" })

  return (
    <locationContext.Provider value={{ ...data }}>
      <setLocationContext.Provider value={setData}>
        {children}
      </setLocationContext.Provider>
    </locationContext.Provider>
  )
}

export const useLocationData = () => {
  const data = React.useContext(locationContext)
  if (data === undefined) {
    throw new Error(
      "useLocationData must be used within a HeaderLinkContextProvider"
    )
  }
  return data
}

export const useSetLocationData = () => {
  const setData = React.useContext(setLocationContext)
  if (setData === undefined) {
    throw new Error(
      "useSetActiveState must be used within a HeaderLinkContextProvider"
    )
  }
  return setData
}

export default LocationContextProvider
